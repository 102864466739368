#barWrapper{
    height: 50px;
    margin: auto;
    background-color: var(--quility-dark-green);
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1;
}
.imageWrapper{
    width: 25%;
    height: 100%;
    line-height: 50px;
    text-align: center;
    flex: 1;
}
.mainLogo{
    height: 50%;
    margin-top: 15px;
}
.searchBar{
    flex: 2;
    text-align: center;
    line-height: 50px;
    position: relative;
}
.otherBarOptions{
    flex: 1;
}
.resultsHolder{
    z-index: 1;
    position: absolute;
    background-color: #ffffff;
    width: 80%;
    margin-left: 10%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: none;
    line-height: normal;
    box-shadow: 3px 3px 15px -5px;
    max-height: 300px;
    overflow: auto;
    margin-top: -8px;
}
.searchBarInput{
    width: 80%;
    height: 30px;
    border-radius: 5px;
    border: solid 1px var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    font-size: 20px;
    text-indent: 5px;
}

.searchResult{
    text-align: left;
    font-size:18px;
    padding:5px;
    cursor:pointer;
    user-select: none;
}
.searchName{
    display:inline-block;
    width:35%;
    min-width:200px;
}
.searchNumber{
    display:inline-block;
    width: 20%;
    min-width:150px;
}
.searchResult:hover{
    background-color: #34609e38;
}