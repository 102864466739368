.softPhoneWrapper{
    position: fixed;
    top: 60px;
    width: 500px;
    height: 240px;
    background-color: #fbfbfb;
    border-radius: 10px;
    left: 60px;
    border: solid 1px #c5d9f0;
    box-shadow: 3px 3px 25px -5px;
    color: #02357e;
    user-select: none;
    z-index: 5;
    
}
.viewLeadPhone{
    cursor: pointer;
    background-color: #c5d9f0;
    padding: 2px 5px;
    border-radius: 5px;
}
.softPhoneButtonStyle{
    border: solid 1px #c5d9f0;
    background-color: #e4effc;
}
.callWith{
    width: 200px;
    display: inline-block;
}
.wrapperTopBar{
    background-color: var(--quility-medium-dark-green);
    height: 35px;
}
.wrapperTopBarTitle{
    font-size: 15px;
    line-height: 35px;
    text-align: left;
    text-indent: 20px;
    color: var(--quility-light-text);
    font-weight: 700;
}
.lineTitle{
    width: 120px;
    font-weight: 700;
    display: block;
}
.softPhoneLine{
    line-height: normal;
    padding-left: 10px;
    font-size: 13px;
    margin: 10px 0px;
    color: #fff;
}
.softPhoneButtonWrapper{
    display: flex;
    height: 50px;
    line-height: 50px;
    text-align: center;
    justify-content: center;
}
.callStatus{
    display: block;
}
.softPhoneButton{
    width: 100px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px 10px;
    font-size: .9em;
}
.reject{
    border: solid 1px #9e0000;
    background-color:#ffbdbd;
}
.answer{
    border: solid 1px #00a800;
    background-color:#bbffbb
}
.buttonIcon{
    margin-left: 5px;
}
.activePhoneButton{
    background-color:#57a4ff;
}
.staticPhoneWrapper{
    width: 90%;
    height: 0px;
    background-color: #fbfbfb;
    border-radius: 5px;
    -webkit-user-select: none;
    user-select: none;
    margin: 10px auto 0px;
    transition: all .5s;
    overflow: hidden;
}
.openPhone{
    border: solid 1px var(--quility-extra-dark-green);
    height: 215px;
    margin-bottom: 5px;
    background-color: var(--quility-extra-dark-green);
}
.keypadHolder{
    position: absolute;
    border: solid;
    z-index: 2;
    width: 250px;
    height: 320px;
    border-radius: 5px;
    background-color: #e4effc;
}
.keypadHeader{
    line-height: 40px;
    background-color: #e4effc;
    font-weight: 700;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: black;
}
.keypadNumber{
    flex: 0 0 33%;
    display: inline-block;
    margin: 5px 0px;

}
.innerKeypad{
    width: 50px;
    display: inline-block;
    height: 50px;
    border-radius: 5px;
    background: #1A4981 0% 0% no-repeat padding-box;
    border: 4px solid #426896;
    color: #ffffff;
    font-weight: 700;
}
.keypadContent{
    display: flex;
    flex-wrap: wrap;
    background-color: #e4effc;
}
.formButtonWrapper{
    text-align: center;
    padding: 15px;
}