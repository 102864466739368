.imageStyle{
    width: 60%;
    margin-top: 30px;
}
.mainBackground{
    font-family: Quicksand,sans-serif;
    height: 100%;
    overflow-y: hidden;
    background: transparent linear-gradient(180deg, #00357E 0%, #2D73D3 100%) 0% 0% no-repeat padding-box;
}
body{
    margin:0px;
    color:#031f4e;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');
.pageTitle{	
	margin-bottom: 20px;
    font-size: 29px;
}
.loginWrapper{
    padding: 30px 0px;
    width: 390px;
    border: solid 1px #56879a42;
    text-align: center;
    overflow: auto;
    margin: 6% auto 0%;
    height: auto;
    color: #fbfbfbfb;
    background: #0007361C 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #455B6314;
    border-radius: 12px;
    opacity: 1;
}
.inputBox{
    border: solid 0.5px #183b6b;
    font-size: 18px;
    margin: 5px 0px;
    width: 80%;
    font-family: inherit;
    color: #ffffff;
    text-align: center;
    height: 48px;
    background-color: #0f3f81;
    border-radius: 12px;
    opacity: 1;
}
.loginButton{
    height: 40px;
    width: 200px;
    font-size: 20px;
    box-shadow: 4px 4px 15px -5px var(--quility-extra-dark-green);
    border: solid 1px var(--quility-extra-dark-green);
    background-color: var(--quility-extra-dark-green);
}
.miniButton{
	border: solid 1px #102f66;
    height:40px;
    border-radius: 5px;
    display: inline-block;
    width: 200px;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    box-shadow: 4px 4px 15px -5px #102f66;
    background-color: #102f66;
    color: #fbfbfb;
}
.miniButton:hover{
	background-color: #44597d;	
}
.errorHolder{
	font-size: 22px;
    color: red;
    font-weight: 700;
    margin-top: -15px;
    margin-bottom: 10px;
}
.buttonWrapper{
	padding-top:10px;
}
.newPasswordContent{
	box-shadow: 0px 0px 20px 2px #031f4e;
    border: solid 1px #031f4e;
    border-radius: 5px;
    width: 700px;
    height: 400px;
    background-color: #fbfbfb;
    margin: 10% auto;
}
.newPasswordWrapper{
	position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
	display:none;
}
.title{
	text-align: center;
    font-size: 22px;
    margin-top: 20px;
}
.subTitle{
	font-size:18px;
	margin: 10px;
}
.passRequirements{
	position: absolute;
    text-align: left;
    font-size: 15px;
    margin-left: 8px;
    margin-top: -8px;
}
.submitNewPassword{
	border: solid 1px;
    width: fit-content;
    margin: auto;
    padding: 8px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 18px;
}
.forgotPassword{
	color:inherit;
}
.backToHome{
	position:absolute;
	margin: auto;
    width: 100%;
    text-align: center;
    margin-top: -50px;
    font-size: 22px;
}
.logo{
    width: 500px;
    text-align: center;
    overflow: auto;
    margin: 10% auto 0%;
    height: auto;
    color: #fbfbfbfb;
    border-radius: 12px;
    opacity: 1;
    padding: 0px 0px 15px;
}