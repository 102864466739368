.qualifierBlock{
    outline: solid 1px;
    height: -moz-fit-content;
    height: fit-content;
    margin: 4px;
}
.conditionBubble{
    border: solid .5px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 20px;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    margin: 5px;
    box-shadow: 2px 2px 10px -5px;
    cursor: pointer;
    user-select: none;
    font-size: 13px;
}
.qualifierSection{
    padding: 10px;
    border-radius: 5px;
}
.qualifierFieldWrapper{
    display: inline-block;
    width: 50%;
    border-bottom: solid 1px #f1f1f1;
    padding: 10px 0px;
}
.qualifierFieldTitle{
    display: inline-block;
    font-size: 13px;
    width: 70px;
    font-weight: 700;
}
.qualifierFieldQuestionTitle{
    max-width: unset;
    width: 50%;
    font-weight: 700;
    display: inline-block;
}
.qualifierFieldInput{
    height: 20px;
    border-radius: 5px;
    border: solid 1px var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    padding: 5px;
    max-width: 200px;
    width: calc(95% - 40px);
    color: var(--quility-dark-text);
}
.conditionQuestionContent{
    background-color: #ffffff;
    max-width: 550px;
    width: 50%;
    margin: auto;
    margin-top: 10%;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 400px;
    padding: 20px;
    border: solid 1px #b7b7b7;
    border-radius: 5px;
}
.conditionQuestionWrapper{
    position: fixed;
    z-index: 12;
    background-color: #567cb13d;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
}
.qualDropdownItem{
    padding:3px;
    cursor:pointer;
}
.qualDropdownItem:hover{
    background-color: var(--quility-input-background-color);
}
.qualDropDownMenu{

    height: 130px;
    border: solid 1px #d5d4d4;
    overflow: auto;
    width: 94%;
    text-align: left;
    padding: 5px;
    box-shadow: 2px 6px 20px -16px;
}
.addTo{
    border: solid 1px #d5d4d4;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 20px;
    line-height: 25px;
    cursor: pointer;
    background-color: var(--quility-input-background-color);
    user-select: none;
    display: inline-block;
    margin: 5px;
    height: 25px;
    font-size: 14px;
}
.medSearchIcon{
    position: absolute;
    margin-left: -30px;
    margin-top: 1px;
    background-color: #c5d9f0;
    line-height: 30px;
    width: 30px;
    text-align: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor:pointer;
}
.conditionHolder{
    margin-bottom: 10px;
    text-align: left;
}
.smallRemove{
    position: absolute;
    background-color: var(--quility-input-background-color);
    border: solid 1px #b7b7b7;
    width: 15px;
    text-align: center;
    height: 15px;
    line-height: 13px;
    border-radius: 50%;
    margin-top: -8px;
    margin-left: 0px;
}
.medicationInfoContent{
    width: 530px;
    height: 300px;
    background-color: #ffffff;
    border: solid 1px #b7b7b7;
    border-radius: 5px;
    margin: auto;
    margin-top: 100px;
    padding: 70px;
    position: relative;
}
.medInfoInner{
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: auto;
    border: solid 1px #b7b7b7;
    padding: 5px;
    border-radius: 5px;
}
.medicationInfoWrapper{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #567cb138;
    width: 100%;
    height: 100%;
    z-index: 3;
}
.medInfo{
    position: absolute;
    background-color: var(--quility-input-background-color);
    border: solid 1px #b7b7b7;
    width: 15px;
    text-align: center;
    height: 15px;
    line-height: 13px;
    border-radius: 50%;
    margin-left: -28px;
    margin-top: 5px;
}