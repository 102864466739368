.extraIndent{
    text-indent: 20px;
    font-weight: unset;
}
.stateOptionsList{
    border: solid 1px;
    width: 260px;
    margin: auto;
    text-align: left;
    text-indent: 10px;
    max-height: 250px;
    height:fit-content;
    position: absolute;
    margin-left: 28%;
    transform: translateX(-50%);
    background-color: #ffffff;
    overflow: auto;
    border-radius: 5px;
    z-index: 2;
}
.option{
    padding: 5px;
    cursor:pointer;
}
.option:hover{
    background: #f1f1f1;
}
.price{    
    margin: 10px;
    font-weight: 700;
}
.submitPaymentFormWrapper{
    width: fit-content;
    margin: 20px auto;
}
.totalPrice{
    margin: 15px 0px;
}