.leftHandBar{
    width: 50px;
    height: calc(100% - 50px);
    background-color: var(--quility-left-side-bar-color);
    display: block;
    position: fixed;
    transition: all .5s;
    z-index: 2;
    margin-top: 50px;
    /*border-right: solid 2px var(--quility-border-color);*/
}
.leftBarIconHolder{
    width: 50px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
}
.leftHandBar:hover{
    width:200px;
}
.additionalItemIcon{
    float: right;
    margin-right: 10px;
    transition: all .5s;
}