.formWrapper{
    width: 51%;
    height: 500px;
    margin: auto;
    padding-top: 30px;
}
.p-PaymentDetails-group{
    color: #00357f;
}
.cancelled{
    background-color: #ffe9e9;
}