.conversationPicker{
    border: solid 1px;
    width: 100px;
    display: inline-block;
    text-align: center;
    height: 25px;
    line-height: 25px;
    border-radius: 10px;
    box-shadow: 3px 3px 10px -5px;
    cursor: pointer;
}
.unreadMessage{
    background-color: var(--quility-hover-grey-color);
}
.convoPickerButtons{
    flex-direction: row;
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 3px;
    justify-content: space-between;
}
.from{
    border: solid 1px;
    border-radius: 10px;
    min-height: 30px;
    max-width: 80%;
    width: fit-content;
    padding: 5px;
    margin: 10px 5px;
}
.pending{
    background-color:#d5d5d5 !important;
}
.conversationWrapper{
    background-color: var(--quility-light-text);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    z-index: 2;
}
.convoPreviewHeader{
    height: 30px;
    line-height: 30px;
    background-color: #c5d9f0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.conversationPreviewWrapper{
    height: 100px;
    width: 90%;
    margin: 10px auto;
    cursor:pointer;
}
.convoPreviewName{
    text-indent: 10px;
    font-weight: 700;
    color: #02357f;
}
.convoPreviewContent{
    background-color: var(--quility-light-text);
    height: calc(100% - 30px);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.convoPreview{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    height: 80%;
    margin: 0px 15px;
}
.convoPreviewTime{
    float: right;
    margin-right: 5px;
}
.expanded{
    width: 100% !important;
}
.conversationCenter{
    flex: 1 1;
    background-color: #f8f8f8;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: calc(100% - 60px);
    position: relative;
    max-width: 35%;
    margin: 0px 10px;
}
.conversationRight{
    max-width: 35%;
    flex: 0.75 1;
    overflow: auto;
}
.conversationLeft{
    flex: 1;
    overflow: auto;
    height: calc(100% - 60px);
}
.convoAdjust{
    flex: .75 !important;
    max-width: 25%;
    margin: 0px 5px;
}
.convoEntireWrapper{
    display:flex;
    height: 100%;
}
.outerConvo{
    height:100%;
}
.convoInnerWrapper{
    height: calc(100% - 100px);
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}
.blockOption{
    margin: 10px 5px;
    font-size: 16px;
}
.blockOptionSelect{
    width: 30px;
    display: inline-block;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    text-indent: 0px;
    border-radius: 50%;
}
.blockOptionSelect:hover{
    background-color:#3a69aa;
    color:#ffffff;
}
.blockOptionSelect:hover > .toolTip{
    display:block;
}
.toolTip{
    font-size: 14px;
    position: absolute;
    width: fit-content;
    background-color: #3a69aa;
    border-radius: 5px;
    padding: 0px 5px;
    display:none;
}
.convoSuggestionItem{
    border: solid 1px #3a69aa;
    width: 150px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: #3a69aa;
    color: var(--quility-light-text);
    box-shadow: 2px 2px 10px -5px #3a69aa;
    font-weight: 700;
    cursor: pointer;
}
.convoSuggestionWrapper{
    height: 50px;
    line-height: 50px;
    background-color: #c5d9f0;
}
.suggestionHolder{
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.pathSuggestion{
    background-color: #dae3ef;
    height: 50px;
}
.suggestion{
    display: inline-block;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    flex: 1 1;
    text-align: center;
    margin: 10px 10px;
    background-color: var(--quility-medium-dark-green);
    color: var(--quility-light-text);
    font-weight: 700;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}
.variableWindow{
    position: absolute;
    height: 250px;
    width: calc(100% - 7px);
    margin-top: -294px;
    border: solid 2px var(--quility-button-color);
    border-radius: 5px;
    overflow: auto;
    background-color: var(--quility-block-header-background-color);
    box-shadow: 0px 0px 50px 10px var(--quility-burst-color);
}
.sendWrapper{
    background-color:var(--quility-medium-dark-green);
}
.convoListInnerWrapper{
    height: calc(100% - 20px);
    overflow: auto;
    margin: 10px;
    background-color: var(--quility-block-header-background-color);
    border-radius: 5px;
}
.rightAdjustment{
    background-color: var(--quility-block-background-color);
    margin: 10px;
    border: solid 1px var(--quility-border-color);
    border-radius: 5px;
    max-width: 40%;
    height: calc(100% - 75px);
    position: relative;
    overflow-x: hidden;
}
.templateSearch{
    width: 90%;
    height: 25px;
    border: solid 1px var(--quility-border-color);
    border-radius: 5px;
    margin: 8px 2%;
}
.addNewConvo{
    position: absolute;
    right: 40px;
    margin-top: 7px;
    border-radius: 20px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    user-select: none;
    cursor: pointer;
    font-weight: 700;
    background-color: var(--quility-accent-background);
    padding: 0px 5px;
    width: fit-content;
    font-size: 11px;
}
.expiredText{
    color: var(--quility-light-text);
    margin: 10px;
}