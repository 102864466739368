.searchNumbers{
    margin-left:10px;
}
.resultName{
    width: 200px;
    display: inline-block;
    text-indent: 0px;
    vertical-align: middle;
}
.resultLocal{
    width: 150px;
    display: inline-block;
    text-indent: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}
.resultCheckBoxWrapper{
    display: inline-block;
    vertical-align: middle;
    text-indent: 0px;
    margin-top: 3px;
    margin-right: 5px;
}
.numberSearchTitle{
    margin-left: 0px;
    text-indent: 0px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 5px;
}
.provisionNumbers{
    border: solid 1px;
    width: fit-content;
    padding: 0px 20px;
    height: 30px;
    line-height: 30px;
    margin: auto;
    border-radius: 20px;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 5px;
}