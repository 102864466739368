#workWindowWrapper{
    background-color: #fbfbfb;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

#workWindowTopBarWrapper{
    height: 70px;
    display: flex;
    background-color: #c5d9f0;
}
.resizable {
    position: relative;
    overflow: hidden;
    border: 1px solid #000;
  }
  
  .handle {
    width: 10px;
    cursor: ew-resize;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #3498db; /* Adjust color as needed */
  }
.windowBlockHeader{
    background-color: #e4effc;
    height: 50px;
}
.windowBlockTitle{
    line-height: 50px;
    text-align: center;
    font-weight: 700;
}
.windowBlockContent{
    background-color: #ffffff;
    height: calc(100% - 50px);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.windowBlockWrapper{
    width: 30%;
    max-width: 600px;
    border: solid 1px #c5d9f0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin: 20px;
    min-height: 55px;
    margin-top: 50px;
    color: #02357e;
    -webkit-user-select: none;
    user-select: none;
    display: inline-block;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all .5s;
    height: 40%;
}
.workWindowButton{
    border: solid 1px;
    flex: 1;
    text-align: center;
    line-height: 70px;
    font-weight: 700;
    color: #02357e;
    cursor: pointer;
    border-top: none;
}
.focus{
    width: 100%;
    max-width: unset;
    margin: auto;
    height: 91%;
    margin-top: -5px;
    position: relative;
}
.unfocus{
    position: absolute;
    top: 0;
    margin: unset;
    width: 49%;
    display: inline-block;
    height: 0px;
    min-height: unset;
    max-width: unset;
    left:0px;
    display: none;
}
.unfocus:last-of-type {
    margin-left:50%;
}

.leftSideBarWrapper{
    width: 22%;
    height: 100%;
    border-right: solid 1px #c5d9f0; 
    display:inline-block;
}
.workPhoneWrapper{
    height: 20%;
    border-bottom: solid 1px;
}
.leadQueueLead{
    width: 96%;
    height: 80px;
    border-radius: 5px;
    margin: 4px;
    margin-left: 1.5%;
    box-shadow: 3px 3px 10px -5px;
    cursor: pointer;
    border: solid 1px #c5d9f0;
    background: #ffffff;
    color: #00357E;
    vertical-align: middle;
}
.leadQueueName{
    font-weight: 700;
    background-color: #e4effc;
    border-bottom: solid 1px #c5d9f0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    text-indent: 5px;
}
.leadQueueStatus{
    margin-left: 20px;
    line-height: 25px;
    font-size: 14px;
    color: #617cac;
}
.leadQueueContact{
    line-height: 25px;
    margin-left: 20px;
    font-size: 14px;
}

.queueButton{
    width: 80px;
    border: solid 1px;
    border-radius: 5px;
    text-align: center;
    flex: 1 1;
    display: inline-block;
    margin: 25px 5px;
    height: 30px;
    line-height: 30px;
    box-shadow: 3px 3px 10px -5px;
    background-color: #e4effc;
    border: solid 1px #c5d9f0;
}
.queueTextBar{
    display: flex;
    box-shadow: 0px 10px 20px -15px;
}
.leadQueueMessagePreview{
    color: #031e4e9c;
    font-size: 14px;
    height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 25px;
}
.leadQueueWrapper{
    overflow: scroll;
    height: 89%;
}
.leadQueueNameInner{
    width: 80%;
    display: inline-block;
}
.leadQueueOptionHolder{
    display: inline-block;
    width: calc(20% - 5px);
}
.leadQueueOption{
    flex: 1;
}
.leadQueueOptionInner{
    display: flex;
}
.messageModal{
    position: absolute;
    border: solid 0.5px #c5d9f0;;
    top: 50px;
    left: 22%;
    height: calc(100% - 50px);
    width: 0;
    transition: all .5s;
    overflow: hidden;
}
.showMessageModal{
    width: 50%;
    border: solid 1px #c5d9f0;
    border-top: none;
    border-bottom: none;
    background-color:#fbfbfb;
}
.conversationButton{
    border: solid 1px;
    width: 160px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
    margin: 10px;
    max-width: 200px;
    cursor:pointer;
}
.toWrapper{
    display: flex;
    justify-content: center;
}
.conversationHolder{
    display:none;
    overflow: auto;
    height: 80%;
}
.viewing{
    background-color: #f8fafe;
    overflow: auto;
    margin: auto;
    height: calc(100% - 150px);
    width: calc(100% - 12px);
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden;
}
.conversationInputWrapper{
    height: 90px;
}
.conversationInputBarWrapper{
    height: 50%;
    border: solid 1px #02357e;
    width: 70%;
    border-radius: 10px;
    margin-top: 3%;
    margin-left: calc(30% - 2px);
    color: #02357e;
}
.conversationInput{
    height: calc(100% - 3px);
    width: 85%;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    color: inherit;
    font-size: 20px;
    font-family: inherit;
}
.innerConvoWrap{
    display: block;
}
.conversation{
    height: 100%;
    position: relative;
    z-index: 1;
}
.sessionConvoBubble{
    border-radius: 10px;
    max-width: 60%;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    margin: 35px 5px;
    overflow-wrap: break-word;
    border: solid 1px;
    font-size: 16px;
}
.sessionfrom{
    border-color: #c5d9f0;
    margin-left: 37%;
    background-color: #fbfbfb;
    box-shadow: 3px 3px 10px -5px;
}
.sessionto{
    background-color: #e4effc;
    border-color: #c5d9f0;
    box-shadow: 3px 3px 10px -5px;
}
.centerBarWrapper{
    width: 50%;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    border-right: solid 1px #c5d9f0;
}
.statusName{
    font-size: 15px;
    margin-left: 10px;
    border-radius: 10px;
    background-color: var(--quility-accent-background);
    padding: 4px 15px;
    font-weight: 700;
    margin-top: -13px;
    vertical-align: super;
}
.addressSlot{
    margin: 2px;
}
.leadType{
    text-align: center;
}
.leadCardSubTitle{
    font-size: 18px;
    font-weight: 700;
    margin-left: 20px;
    background-color: #ffffff;
    color: #02357e;
    z-index: 0;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 5px;
    border-radius: 5px;
    border: solid 0.75px #69afff;
    border-bottom: none;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    max-width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: -49px;
}
.leadCardSubTitleStable{
    font-size: 21px;
    font-weight: 700;
    margin-left:20px;
    margin-top: 20px;
}
.leadCardSubNode{
    border: solid 1px;
    width: 90%;
    margin: 30px auto;
    height: 50px;
    line-height: 50px;
    text-indent: 20px;
    border-radius: 10px;
    background-color: #4bb6a6;
}
.cardInfoItem{
    margin: 3px 0px;
}
.cardInfoItem:hover > .hoverIcon{
    display:inline-block;
}
.leadCardSubTitle:hover > .hoverIcon{
    display:inline-block;
}
.returnToLeadCard{
    position: absolute;
    margin-top: -10px;
    height: 30px;
    line-height: 30px;
    padding: 0px 10px;
    user-select: none;
    cursor:pointer;
    border-radius: 5px;
}
.addressHolder{
    text-align: left;
    text-indent: 25px;
    font-size: 14px;
    display: inline-block;
    width: 60%;
    margin-bottom: 5px;
    color: var(--quility-dark-text);
}
.leadCardAddressWrapper{
    background-color: var(--quility-block-header-background-color)
}
.badgeHolder{
    display: inline-block;
    width: 40%;
}
.double{
    width: calc(50% - 40px);
    display: inline-block;
    vertical-align: top;
}
.emptyInfo{
    height:0px;
    overflow:auto;
    transition:all .5s;
}
.isOpen{
    height: 140px;
    margin-top: -15px;
}

.openArrow{
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}
.rotate{
    transform: rotate(180deg);
}
.transition{
    transition: all .5s;
}
.quilityActiveTab{
    color: var(--quility-button-color);
    font-weight: 900;
    text-decoration: underline;
}