.teamsWrapper{
    text-align: center;
    margin: auto;
}
.smallTitleInfo{
    font-size: 15px;
    position: absolute;
    right: 25px;
}
.teamModalName{
    flex: 1 1;
    text-align: left;
    text-indent: 20px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.teamModalStat{
    flex: 1 1;
    cursor: pointer;
    text-align: left;
    font-weight: normal;
}
.smallTeam{
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    margin: 15px;
    display: block;
}
.detailTitleWidth{
    width: 250px;
    display: inline-block;
}
.open{
    margin-left: 50px;
    display: inline-block;
    transition:all .5s;
}
.openIcon{
    transform: rotateZ(90deg);
}
.openUp{
    height: 300px !important;
    max-height: 300px;
    overflow: auto !important;
}
.detailModalWrapper{
    width: 80%;
    margin: auto;
    border: solid 1px #c5d9f0;
    margin-left: 10%;
    min-height: 400px;
    border-radius: 10px;
    background-color: #ffffff;
    height: 50%;
    position: relative;
}
.detailCount{
    margin-left: 80px;
    font-size: 14px;
    width: 200px;
    display: inline-block;
}
.totalCount{
    position: absolute;
    margin-left: 100px;
    font-size: 16px;
}
.detailWrapper{
    height: 0px;
    overflow: hidden;
    transition: all .5s;
    cursor:default;
}
.detailModalTopBar{
    background-color: #e4effc;
    border-bottom: solid 1px #c5d9f0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    min-height: 55px;
    color: #02357e;
    -webkit-user-select: none;
    user-select: none;
}
.detailModalContent{
    height: calc(100% - 60px);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: auto;
    padding: 20px 0px;
}
.detailBlockTitle{
    font-size: 16px;
    color: #313131;
    font-weight: 700;
    cursor: pointer;
    line-height: 50px;
    width: 90%;
    border-radius: 12px;
    background-color: #D9F2ED;
    text-indent: 16px;
    border: 1px solid #DCE5EF;
    text-align: left;
    margin: 20px auto;
}
.detailItem{
    line-height: 40px;
    color: #313131;
    cursor: default;
}
.detailItem:hover{
    background-color: #F0EDFD;
}
.detailTitle{
    margin-left: 15px;
    display: inline-block;
    vertical-align: top;
    width: 40%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}
.detailDate{
    margin-left:5px;
    width: 150px;
    display: inline-block;
    vertical-align: top;
}
.detailTimezone{
    margin-left: 5px;
    width: 150px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.agentNameDetail{
    display: inline-block;
    width: 400px;
}
.ViewLead{
    border-radius: 5px;
    padding: 5px;
    cursor:pointer;
    margin-left: 50px;
}
.detailDialDate{
    margin-left: 20px;
    width: 250px;
    display: inline-block;
}
.detailDialCount{
    width: 150px;
    display: inline-block;
}
.noAgent{
    background-color: #e3e7ed;
}
.bigBlockAdjustment{
    width: 500px;
    margin: auto;
}
.actionSlot{
    flex: 1;
    float: right;
}
.dataSlot{
    flex: 1;
}
.bottomButtonWrapper{
    display: flex;
    justify-content: space-between;
}
.memberWrapper{
    height: 40px;
    line-height: 40px;
}
.memberName{
    flex: 2;
}
.memberWrapper{
    display:flex;
}
.imoOptionButton{
    width: 150px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: auto;
    margin-top: 20px;
    cursor:pointer;
    text-align: center;
}
.imoLinkContent{
    width: 50%;
    height: 200px;
    background-color: #ffffff;
    margin: auto;
    border: solid 1px var(--quility-border-color);
    border-radius: 5px;
    margin-top: 100px;
    position:relative;
}
.imoLinkWrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    background-color: var(--quility-veil);
}
.clickToLink{
    height: 30px;
    width: 200px;
    margin: auto;
    line-height: 30px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}
.teamModule{
    position: absolute;
    background-color: #ffffff;
    width: 60%;
    margin: auto;
    top: 0;
    z-index: 2;
    border: solid 4px var(--quility-border-color);
    border-radius: 5px;
    margin-left: 22%;
    height: calc(100% + 120px);
}
.addTeamFieldInput{
    height: 20px;
    border-radius: 5px;
    border: solid 1px var(--quility-input-border-color);
    background-color: var(--quility-input-background-color);
    padding: 5px;
    max-width: 70%;
    width: calc(95% - 40px);
    color: var(--quility-dark-text);
}
.teamFieldWrapper{
    display: inline-block;
    width: 100%;
    border-bottom: solid 1px #f1f1f1;
    padding: 10px 0px;
    text-align: center;
}
.teamFieldTitle{
    font-size: 18px;
    width: 71%;
    font-weight: 700;
    text-align: left;
    margin: auto;
    margin-bottom:10px;
    margin-top:10px;
}
.goalsWrapper{
    width: calc(70% - 30px);
    margin: auto;
    text-align: left;
    background-color: var(--quility-block-background-color);
    padding: 20px;
    border-radius: 5px;
    border: solid 1px var(--quility-border-color);
}
.goalOption{
    padding: 10px;
    font-weight: 700;
    cursor:pointer;
}