.warningBody{
    position: absolute;
    width: 70%;
    background-color: #ffffff;
    z-index: 1;
    height: 30%;
    border-radius: 10px;
    max-width: 700px;
    margin-top: 130px;
    min-height: 300px;
}
.warningHeader{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    min-height: 40px;
    -webkit-user-select: none;
    user-select: none;
    transition: all .5s;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background-color: var(--quility-extra-dark-green);
    color: var(--quility-light-text);
}
.warningWrapper{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: var(--quility-veil);
    z-index: 5;
    display: flex;
    justify-content: space-around;
}
.inputHolder{
    font-size: 18px;
    margin: 20px;
}
.warningContent{
    text-align: center;
    font-size: 26px;
    margin: 25px;
    color: black;
}
.requirements{
    position: absolute;
    right: 10px;
    top: 45%;
    font-size: 16px;
    text-align: left;
}
.warningButton{
    display: inline-block;
    width: 140px;
    margin: 10px;
}
.warningButtonWrapper{
    width: 100%;
    display: inline-block;
    text-align: center;
}
.warningButtonHolder{
    width: 100%;
}
.listStyle{
    width: 50%;
    margin: auto;
    font-size: 20px;
    color: #00357f;
}