.modalTopBar{
    width: 45%;
    max-width: 600px;
    background-color: #e4effc;
    border: solid 1px #c5d9f0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin: 20px;
    min-height: 55px;
    margin-top: 50px;
    color: #02357e;
    -webkit-user-select: none;
    user-select: none;
    display: inline-block;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all .5s;
}
.modalIcon{
    position: absolute;
}
.modalTitle{
    position:relative;
    height: 55px;
    text-align: center;
    font-size: 22px;
    line-height: 55px;
    font-weight: 700;
}
.modalContentWrapper{
    background-color: #ffffff;
    min-height: 250px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    max-height: 250px;
    overflow: auto;
}
.modalIcon{
    position: absolute;
    line-height: 55px;
    padding-left: 15px;
    font-size: 26px;
}
.modalWrapper{
    text-align: center;
}
.optionBlock{
    height: 40px;
    line-height: 40px;
    display: flex;
}
.optionBlock:hover{
    background-color: #e4effc;
}
.optionTitle{
    flex: 2 1;
    text-align: left;
    text-indent: 20px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.optionButtons{
    flex: 2;
    display: flex;
}
.optionButton{
    flex:1;
    cursor: pointer;
}

.leadListWrapper{
    width: 90%;
    background-color: #e4effc;
    border: solid 1px #c5d9f0;
    margin-top: 25px;
    color: #02357e;
    -webkit-user-select: none;
    user-select: none;
    display: inline-block;
    border-radius: 10px;
    border-bottom-right-radius: 10px;
    height: calc(95% - 250px);
    border-bottom-left-radius: 10px;
    transition: all .5s;
    margin-left: 5%;
}
.leadListTitle{
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 55px;
}
.leadListSort{
    line-height: 55px;
    position:flex;
    font-weight: 700;
    cursor: pointer;
}
.leadListContentWrapper{
    background-color: #ffffff;
    min-height: 250px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height:100%;
    overflow: auto;
    border: solid 1px #c5d9f0;
}
.minimized{
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 3px 4px 10px -5px;
    cursor: pointer;
    position: absolute;
    margin-left: 1%;
    min-height: unset;
}
.circleTitle{
    padding-top: 32px;
    padding-bottom: 10px;
    font-weight: 700;
    text-align: center;
}
.filterIcon{
    text-align: center;
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    margin: auto;
    cursor:pointer;
    display:inline-block;
    vertical-align: top;
}
.saveAsQueue{
    line-height: 30px;
    display: inline-block;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 700;
    padding: 5px 10px;
}
.queueOptionWrapper{
    width: 90%;
    margin: 0px 20px;
}
.checkAllWrapper{
    position: absolute;
    left: 1.25%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin-top: 16px;
    z-index: 1;
}