
.signupLogo{
    height: 15%;
    padding-top: 15px;
    max-height: 80px;
    text-align: center;
    margin: auto;
    padding-bottom: 140px;
}
.signUpWrapper{
    width: 70%;
    margin: auto;
    text-align: center;
}
.completeSignUpWrapper{
    background-color: var(--background-color);
}
.searchMe{
    position: absolute;
    display: inline-block;
    height: 30px;
    border-radius: 5px;
    line-height: 30px;
    padding: 0px 10px;
    margin-left: 30px;
    cursor: pointer;
    user-select: none;
}
.passwordErrors{
    position: absolute;
    display: inline-block;
    border: solid 2px var(--quility-border-color);
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 11px -5px;
    width: 220px;
    margin-left: 10px;
}
.existingAgent{
    width: 100%;
    text-align: center;
    color: #b00000;
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
}
.goToLogin{
    width: fit-content;
    padding: 0px 10px;
    line-height: 35px;
    margin: auto;
    border-radius: 5px;
    user-select: none;
    margin-top: 20px;
}
.noExist{
    display: block;
    color: #bf0000;
}
.submitSubscription{
    display: inline-block;
    border-radius: 5px;
    line-height: 40px;
    padding: 0px 10px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}