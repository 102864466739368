.recordingItem{
    text-indent: 0px;
    margin-left: 80px;
    width: calc(100% - 80px);
}
.audioPlayerStyles{
    width: 50%;
}
.audioElementAdjustment{
    margin-bottom:30px;
    text-align: center;
}
.recordedWrapper{
    margin:20px;
}
.audio-recorder{
    margin: 10px auto;
}
.adjust{
    margin:auto;
    margin-top:15px;
}