.navigationTile{
    display: inline-block;
    flex: 1 1;
    max-width: 170px;
    border-bottom-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    border: solid 0.5px #3a69aa;
    background-color: #3a69aa;
}
.navigationBarWrapper{
    display: flex;
    width: 100%;
    position: absolute;
    margin-left: -20px;
    height: 70px;
    border-radius: 10px;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
.navigationIcon{
    margin-right: 10px;
}
.navigationTitle{
    color: #00357f;
    display: block;
    font-size: 18px;
    line-height: 70px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    padding: 0px 5px;
}
.pastTile{
    background-color: #00357f;
    cursor: pointer;
    border-radius: 0px;
    border-top: none;
}
.navHolder{
    height:70px;
}
.navigationTile:nth-last-child(2) {
    border-bottom-right-radius: 10px;
}
.pastTile:first-child {
    /*border-top-left-radius: 10px;*/
}
.navigationTile:last-of-type{
    /*max-width: fit-content;*/
}
.navigationTile:first-of-type{
    max-width: 170px;
}
.navigationTitle:last-of-type{
    color:#ffffff;
}