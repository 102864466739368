.bulkActionFooterButton{
    flex: 1 1;
    line-height: 40px;
    font-weight: 700;
    position: relative;
}
.bulkActionFooterIcon:hover > .hoverTip {
    display:block;
}
.smartLists{
    margin-top: 20px;
}
.smallSmartListButton{
    width: calc(50% - 10px);
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    transition: all .5s;
    vertical-align: top;
    margin: 5px 0px;
}
.hoverTip{
    display: none;
    position: absolute;
    background-color: var(--quility-button-color);
    margin-top: -4px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 13px;
    width: 100%;
    padding: 5px 0px;
    line-height: normal;
}
.largeSmartListButton{
    width: 60%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
    transition: all .5s;
}
.largeSmartListButton:hover{
    height:80px;
}
.smallSmartListButton:hover{
    height:70px;
}
.bulkActionFooterWrapper{
    display: flex;
    background-color: var(--quility-button-color);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
    color: var(--quility-color-button-text-color);
}
.smallListWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: calc(60% + 10px);
    margin: 5px auto;
}
.selectionCount{
    flex: 1.3;
    height: 30px;
    font-weight: 700;
    font-size: 13px;
    background-color: var(--quility-medium-dark-green);
    line-height: 30px;
    margin-top: 5px;
    border-radius: 5px;
    margin-left: 10px;
    padding: 0px 5px;
    color: #ffff;
    display: inline-block;
}
.bulkSelectionHolder{
    position: fixed;
    background-color: #00357f2b;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    overflow: auto;
    left: -1px;
    transition: all .5s;
    box-shadow: -1px -6px 40px -22px;
    border: solid 1px var(--quility-border-color);
    display: none;
}
.showList{
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: block;
}
.bulkSelectionContent{
    width: 40%;
    height: 50%;
    overflow: auto;
    margin: auto;
    margin-top: 100px;
    border-radius: 5px;
    border: solid 2px var(--quility-border-color);
    background-color: var(--quility-block-background-color);
    padding: 15px 50px;
    position: relative;
    box-shadow: 0px 1px 150px 50px var(--quility-burst-color);
}
.bulkSelectionChoice{
    font-size: 14px;
    text-align: left;
    line-height: normal;
    padding: 5px 15px;
}