.a2papproved{
    background-color:#daffda;
}
.a2pdraft{
    background-color:#e6e6e6;
}
.a2prejected{
    background-color:#ffd6d6;
}
.a2pin-review{
    background-color: #ffffc5;
}
.inUse{
    margin-top: 25px;
    box-shadow: 3px 3px 20px -5px;
    border: solid 1px #00367f;
}
.a2pVERIFIED{
    background-color:#daffda;
}
.a2pAPPROVED{
    background-color:#daffda;
}
.a2pFAILED{
    background-color:#ffd6d6;
}
.a2pIN_PROGRESS{
    background-color: #ffffc5;
}
.compliantWrapper{
    border: solid 1px;
    border-radius: 10px;
    background-color: #daffda;
    height: 70px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
}
.formTitle{
    text-align: center;
    font-size: 32px;
    padding: 20px;
    font-weight: 700;
}
.quilityPopupFormWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00357f33;
    z-index: 5;
}
.quilityPopupFormContent{
    background-color: #ffffff;
    max-width: 800px;
    margin: auto;
    height: 100%;
    width: 80%;
    min-width: 400px;
    box-shadow: 3px 3px 20px -5px;
    position: relative;
}
.bigSelectionButton{
    width: 200px;
    height: 60px;
    line-height: 60px;
    margin: 20px auto;
    text-align: center;
    font-weight: 700;
    color: #ffff;
    border-radius: 5px;
    cursor: pointer;
}
.trustFormWrapper{
    margin: 15px;
    border: solid 0.5px #b7b7b7;
    padding: 15px;
    border-radius: 5px;
}
.trustFormTitle{
    font-weight: 700;
    font-size: 18px;
    margin: 10px 0px;
}
.startOverButton{
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    color: #ffff;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
}
.clarification{
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: 90%;
    color: #9b9b9bfc;
}
.clarification:hover{
    overflow:unset;
}
.greyInfo{
    font-size: 14px;
    margin-left: 10px;
    display: inline-block;
    color: grey;
}
.quilityFormWrapper{
    overflow: auto;
    height: calc(100% - 90px);
}
.formProfileSubmitButton{
    width: 200px;
    height: 40px;
    line-height: 30px;
    margin: 20px auto;
    text-align: center;
    font-weight: 700;
    color: #ffff;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 18px;
}
.brandSelection{
    border: solid 1px;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 5px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin: auto;
    cursor: pointer;
    user-select: none;
    margin: 10px auto;
}
.campaignFormInputWrapper{
    margin: 20px;
}
.campaignFormFieldWrapper{
    display: inline-block;
    font-size: 16px;
    width: 30%;
    font-weight: 700;
}
.campaignLargeInput{
    border-radius: 5px;
    border: solid 1px #a4a4a4bf;
    background-color: #efefef;
    padding: 5px;
    width: 95%;
    height: 120px;
    resize: none;
    font-family:inherit;
    color:inherit;
}
.campaignTrustFormWrapper{
    margin: 15px;
    border: solid 0.5px #b7b7b7;
    padding: 15px;
    border-radius: 5px;
    overflow: auto;
    height: calc(100% - 130px);
}
.campaignItemWrapper{
    margin: 15px 20px;
}
.campaignFormTitle{
    display: inline-block;
    font-size: 16px;
    width: 30%;
    font-weight: 700;
    margin: 5px 0px;
}
.submitButton{
    width: 150px;
    display: inline-block;
    height: 40px;
    margin: 0px 10px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    color:#ffffff;
}
.addNumberButton{
    cursor:pointer;
    border-radius: 20px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 10px;
    height: 25px;
    line-height: 25px;
    position: relative;
    display: inline-block;
    background-color: #f1f1f1;
    color: black;
    max-width: 110px;
    font-size: 13px;
    vertical-align: top;
}
.serviceNumber{
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    cursor: default;
    margin: 8px 0px;
    text-decoration: unset;
    background-color: var(--quility-button-color);
}