.queueModuleTab{
    width: 40px;
    height: 40px;
    position: fixed;
    margin-top: 215px;
    text-align: center;
    line-height: 40px;
    background-color: var(--quility-dark-green);
    color: #ffffff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 0px;
    box-shadow: 3px 3px 10px -7px;
    cursor: pointer;
    transition: all .5s;
    z-index: 1;
    right: 0;
}
.queueModuleTab:hover{
    width:200px;
}
.queueWrapper{
    width: 350px;
    height: 100%;
    position: fixed;
    z-index: 3;
    border-right: solid 1px #c5d9f0;
    box-shadow: -5px 0px 30px 0 var(--quility-burst-color);
    transition: all .5s;
    background-color: var(--background-color);
    right: 0;
}
.checkBox{
    display:inline-block;
    width: '20px';
    height: '20px';
    border: '1px solid #000';
    border-radius: '50%';
    display: 'inline-block';
}
.queueItemName{
    display: inline-block;
    vertical-align: middle;
}
.queueItem{
    margin: 9px auto;
    border-radius: 10px;
    transition: all .5s;
}
.queueInner{
    display: inline-block;
    vertical-align: middle;
}
.queueTitle{
    font-size: 22px;
    display: inline-block;
    max-width: 350px;
    line-height: 50px;
    cursor: pointer;
    border-bottom: none;
    background-color: var(--quility-dark-green);
    color: #ffff;
    min-width: 300px;
    text-align: left;
}
.titleInner{
    width: 90%;
    border-bottom: solid 1px #97b9b6;
    font-size: 30px;
    font-weight: 700;
    margin: 15px auto;
}
.actualQueue{
    background-color: var(--quility-dark-green);
    height: 100%;
    color: #ffffff;
    padding-top: 5px;
    max-width: 350px;
    flex: 1 1;
    min-width: 300px;
    overflow: auto;
}
.activeTab{
    background-color: #00357f !important;
}
.actualName{
    width: 200px;
    display: inline-block;
}
.categoryBlock{
    border: solid 1px #5085ce;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 0px 5px;
    cursor: pointer;
    background-color: #3a69aa;
}
.queueCategories{
    padding: 15px 0px;
}
.startSession{
    display: inline-block;
}
.fullScreen{
    width:100%;
}
.dialSessionButtonWrapper{
    text-align: center;
    margin: 10px 0px 20px;
}
.centerQueue{
    flex: 2 1;
    background-color: var(--quility-block-background-color);
    margin: 15px;
    position: relative;
    border: solid 2px var(--quility-border-color);;
    border-radius: 3px;
    overflow: hidden;
}
.innerQueueWrapper{
    height: 100%;
    width: 100%;
    display: flex;
}
.rightQueue{
    flex: 1.3 1;
    background-color: var(--quility-block-header-background-color);
    margin: 45px 15px 0px 0px;
    position: relative;
    border: solid 2px var(--quility-border-color);;
    border-radius: 3px;
    overflow: hidden;
    height: calc(100% - 50px);
}
.rightHeaderWrapper{
    width: 90%;
    margin: 20px;
    line-height: 45px;
    background-color: var(--quility-block-header-background-color);
    text-align: left;
    border-radius: 3px;
    color: var(--quility-dark-text);
}
.queueHeaderBlock{
    width: 60px;
    font-size: 15px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    /*text-align: center;
    font-size: 16px;
    display: inline-block;
    border: solid 1px #567CB1;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    line-height: 50px;
    margin-left: 1px;
    cursor: pointer;
    border-bottom: none;
    background-color: #567CB1;
    color: #ffff;
    flex: 1;
    font-weight: 700;
    user-select: none;*/
}
.scriptDropWrapper{
    text-align: center;
    padding: 20px;
}
.scriptContainerHeader{
    background-color: var(--quility-nested-block-background-color);
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: var(--quility-dark-text);
    -webkit-user-select: none;
    user-select: none;
    border-radius: 5px;
}
.scriptContainer{
    height: 67%;
    width: 90%;
    margin: auto;
    position: relative;
    /*border: solid 2px #f1f1f1;*/
    border-radius: 5px;
    margin-bottom: 5px;
}
.scriptContainerContent{
    background-color: var(--quility-nested-block-background-color);
    height: 90%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    width: 100%;
    overflow: auto;
    color: var(--quility-dark-text);
}
.entireScriptWrapper{
    height: 86%;
}
.leadInformationBlock{
    font-size: 16px;
    color: #313131;
    font-weight: 700;
    cursor: pointer;
    width: 90%;
    border-radius: 12px;
    background-color: #D9F2ED;
    text-indent: 16px;
    border: 1px solid #DCE5EF;
    text-align: left;
    margin: 20px auto;
    height: 45px;
    overflow: hidden;
    transition: all .5s;
}
.leadInfoTitle{
    font-size: 20px;
    margin: 10px 0px;
}
.doubleLine{
    width: calc(50%);
    display: inline-block;
    vertical-align: top;
}
.leadInfoLine{
    padding: 10px 0px;
    font-size: 13px;
    border-bottom: solid 1px #f1f1f1;
}
.mainInfo{
    background-color: #F0EDFD;
    border: 1px solid #DCE5EF;
    font-size: 16px;
    color: #313131;
    font-weight: 700;
    cursor: pointer;
    width: 90%;
    border-radius: 12px;
    text-indent: 16px;
    text-align: left;
    margin: 20px auto;
    height: 45px;
    overflow: hidden;
    transition: all .5s;

}
.dialAmountSelect{
    border: solid 1px var(--quility-extra-dark-green);
    display: inline-block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 0px 5px;
    cursor: pointer;
    background-color: var(--quility-extra-dark-green);
}
.dialAmountWrapper{
    position: absolute;
    top: 25px;
    left: -1px;
    border: solid 1px var(--quility-medium-dark-green);
    display: inline-block;
    border-radius: 5px;
    padding: 0px 10px;
    cursor: pointer;
    background-color: var(--quility-medium-dark-green);
}
.dialAmountSelectBox{
    position: relative;
    border: solid 1px #5085ce;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 0px 5px;
    cursor: pointer;
    background-color: #3a69aa;
}
.currentLead{
    width: calc(100% - 80px);
    display: inline-block;
    border: solid 1px;
    outline: solid 5px #c6fdf1;
}
.queueConvoHeader{
    display: flex;
    color: #031f4e;
}
.conversationTitle{
    line-height: 30px;
    background-color: #f1f1f1;
    text-align: center;
    font-weight: 700;
    padding: 5px;
}
.queueConversationButton{
    flex: 1 1;
    text-align: center;
    background-color: #f1f1f1;
    height: 35px;
    line-height: 40px;
    cursor: pointer;
    font-weight: 700;
}
.queueConversationInnerWrapper{
    background-color:#ffffff;
    height: calc(100% - 180px);
    display: flex;
    margin: auto;
    flex-direction: column-reverse;
    overflow: auto;
    cursor: initial;
    min-height: 200px;
}
.queueConvoWrapper{
    border-radius: 5px;
    height: calc(100% - 102px);
    width: 90%;
    margin: auto;
    color: black;
    border: solid 2px #f1f1f1;
}
.queueConversationWrapper{
    height: 100%;
    background-color: var(--quility-left-side-bar-color);
}
.sentImageWrapper{
    height:100px;
}
.convoBubble{
    border: solid 1px;
    max-width: 70%;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
    text-align: left;
}
.messagefrom{
    text-align: right;
}
.backgroundfrom{
    background-color: var(--quility-extra-dark-green);
    color: var(--quility-light-text);
}
.backgroundto{
    background-color: #d9f2ed;
    border-color: #9cc2ba;
}
.sendButton{
    display: inline-block;
    width: 50px;
    text-align: center;
    color: #ffffff;
    font-size: 22px;
    line-height: 50px;
    vertical-align: top;
}
.messageInput{
    border: solid 1px;
    width: 95%;
    border-radius: 10px;
    min-height: 40px;
    max-height: 150px;
    font-family: inherit;
    font-size: 16px;
}
.inputBarWrapper{
    display: inline-block;
    margin-top: 5px;
    vertical-align: top;
    width: calc(100% - 50px);
    text-align: center;
}
.optionButton{
    display: inline-block;
    color: #ffffff;
    font-size: 24px;
    width: 50px;
    text-align: center;
    line-height: 40px;
}
.queuePhoneButton{
    width: 30px;
    height: 30px;
    display: inline-block;
    border: solid 1px;
    line-height: 30px;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
    font-size: 16px;
    color: var(--theme-light-color);
}
.phoneButtons{
    width: 100%;
}
.disabled{
    color: #aea5a5;
    cursor:default;
}
.filterBlockWrapper{
    position: relative;
    padding-top: 20px;
    color: #00357E;
}
.numberSelectBox{
    border: 1px solid #707070;
    border-radius: 6px;
    opacity: 1;
    width: 130px;
    height: 28px;
    margin: 0px 10px;
}
.hidden{
    display:none;
}
.statusTypeBlock{
    /*flex: 1 1;
    max-width: calc(21% - 8px);
    border: solid 1px #acd2ff;
    margin: 3px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px 0px;
    height: -moz-fit-content;
    height: fit-content;*/
    flex: 1 1;
    border-radius: 5px;
    background-color: var(--quility-nested-block-background-color);
    height: fit-content;
    width: 20%;
}
.statusButtonBlock{
    padding: unset;
    overflow: auto;
    display: flex;
    bottom: -30px;
    position: absolute;
    width: 100%;
    text-indent: unset;
    left: 0;
    overflow-x: auto;
}
.typeHolder{
    display:flex;
    text-align: center;
    font-weight:700;
}
.statusItem{
    background-color: var(--quility-block-background-color);
    border-radius: 5px;
    color: var(--quility-dark-text);
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 33px;
    margin: 10px 5px;
    overflow: hidden;
    text-indent: 0;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    flex: 1;
    border: solid 1px var(--quility-border-color);
    background-color: var(--quility-light-background);
    text-align: center;
    min-width: 100px;
}
/*.statusItem{
    background-color: var(--quility-block-background-color);
    border-radius: 5px;
    color: var(--quility-dark-text);
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 33px;
    margin: 10px 5px;
    overflow: hidden;
    text-indent: 0;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;

}*/
.dispositionTabz{
    border: solid 1px;
    height: 70px;
    line-height: 70px;
    background-color: #d9f2ed;
    position: absolute;
    width: 50px;
    font-size: 26px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    cursor: pointer;
}
.tabIconHolder{
    display: inline-block;
    width: 50px;
    vertical-align: top;
    text-align: center;
    background-color: #d9f2ed;
    height: 60px;
    margin-left: -30px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    line-height: 60px;
    font-size: 22px;
    border: solid 1px #4bb6a6;
    cursor: pointer;
}
.dialOptionsHolder{
    text-align: left;
    width: 90%;
    margin-left: 5%;
}
.quilityDropDown{
    width: 140px;
    background-color: var(--quility-extra-dark-green);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px;
    height: 30px;
    font-family: inherit;
}
.queueItemTitle{
    line-height: normal;
    font-size: 13px;
    font-weight: normal;
}
.dispositionTab{
    position: absolute;
    margin-left: -600px;
    z-index: 0;
    transition:all .5s;
    margin-top: -70px;
}
.dispositionTab:hover{
    margin-left:-550px;
}
.openedDispo{
    margin-left: 0px !important;
}
.leadData{
    font-weight: normal;
    text-decoration: underline;
    height: calc(100% - 60px);
    display: inline-block;
    width: 59%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}
.workFilter{
    display: inline-block;
    text-indent: 0px;
    height: 25px;
    line-height: 25px;
}
.clearQueue{
    margin-left: -150px;
    width: 100px;
    display: inline-block;
    position: absolute;
    margin-top: 5px;
}
.callIcon{
    position: absolute;
    margin-left: -30px;
    text-indent: 0px;
    width: 30px;
    text-align: center;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    margin-top: 5px;
}
.callIcon:hover{
    background-color: #3a69aa;
    color: #ffffff;
    font-size: 16px;
}
.queueScroll{
    overflow: scroll;
    height: calc(100% - 330px);
    transition: all .5s;
}
.fullScroll{
    height: calc(100% - 125px) !important;
}
.notesButtons{
    display: flex;
    padding-top: 20px;
}
.notesButton{
    border: solid 1px #3a69aa;
    width: 60px;
    display: inline-block;
    flex: 1 1;
    margin: 0px 10px;
    border-radius: 5px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: #c5d9f0;
}
.entireNotesWrapper{
    height: 85%;
    width: 90%;
    margin: auto;
}
.notes{
    outline: solid 0.5px;
    height: 60%;
    width: 90%;
    margin-left: 5%;
    border-radius: 5px;
    box-shadow: 2px 2px 15px -5px;
    padding:5px;
    overflow: auto;
    background-color: #ffffff;
}
.notesWrapper{
    height: 100%;
    display:none;
}
.focusButton{
    border-top-right-radius: 200px;
    border-top-left-radius: 200px;
    background: linear-gradient(180deg, #fafafa00 80%, #003580 20%);
}
.topBarInput{
    color: inherit;
    font-family: inherit;
    width: 250px;
    height: 30px;
    font-size: 16px;
    border-radius: 5px;
    border: solid 1px;
}
.selectionOption{
    display: inline-block;
    width: 110px;
    font-weight: 700;
    cursor: pointer !important;
    position: relative;
    margin: 0px 5px;
    border-radius: 5px;
    line-height: 28px;
}
.iconWrapper{
    position:absolute;
    left:1px;
}
.selectedItem{
    background-color: #b6c6dd;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Optional */
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.numberInput{
    height: 30px;
    color: inherit;
    border-radius: 5px;
    width: 40px;
    border: solid 1px;
    text-align: center;
}
.ampmSelector{
    display: block;
    height: 50%;
    font-size: 11px;
    line-height: 20px;
    font-weight: 700;
}
.ampmSelect{
    display: inline-block;
    height: 40px;
    border: solid 1px;
    vertical-align: top;
    width: 40px;
    margin-left: 5px;
    border-radius: 5px;
    margin-top: -5px;display: inline-block;
    height: 40px;
    border: solid 1px;
    vertical-align: top;
    width: 40px;
    margin-left: 5px;
    border-radius: 5px;
    margin-top: -5px;

}
.timeError{
    border-color: red;
}
.attachFileDropZone{
    height: 90%;
    border: dashed 1px;
    width: 96%;
    margin: 2% auto;
}
.attachFileText{
    margin-top: 10%;
}
.objectionWrapper{
    width: 90%;
    margin: auto;
    height: 30%;
    position: relative;
    border: solid 2px #f1f1f1;
    border-radius: 5px;
    margin-bottom: 5px;
}
.objectionHeader{
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #f1f1f1;
}
.objectionContainer{
    height: 100%;
    margin: auto;
    background-color: #ffffff;
    overflow: auto;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0px 5px;
    border: solid 1px #e4effc;
    border-top: none;
}
.objection{
    border: solid 1px var(--quility-color);
    display: inline-block;
    margin: 5px 4px;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--quility-color);
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 2px 2px 10px -5px black;
}
.objectionViewWrapper{
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #4368974f;
    width: 100%;
    height: 100%;
}
.objectionInnerWrapper{
    width: 70%;
    max-width: 700px;
    background-color: #ffffff;
    border-radius: 10px;
    height: 300px;
    margin: 100px auto;
    border: solid 1px;
    padding: 10px;
    position:relative;
}
.objectionContent{
    width: 98%;
    margin: 10px auto 30px;
    height: calc(100% - 30px);
    overflow: auto;
}
.objectionTitle{
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
.addNotesIcon{
    display: inline-block;
    font-weight: normal;
    font-size: 15px;
    margin: 0px 5px;
    height: 30px;
    line-height: 30px;
    width: 40px;
    border-radius: 14px;
    cursor: pointer;
}
.addNotesIcon:hover{
    background-color:#003581;
    color:#ffffff;
    box-shadow: 2px 2px 10px -5px #003581;
}
.addWrapper{
    position: absolute;
    display: inline-block;
    margin-top: -5px;
}
.dataButton{
    display: inline-block;
    width: 200px;
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    box-shadow: 3px 3px 10px -5px;
    cursor: pointer;
    margin: 0px 10px;
}
.workflowButton{
    position: absolute;
    left: 20px;
    top: 25px;
    border: solid 3px #9cd8a5;
    line-height: 30px;
    border-radius: 5px;
    padding: 0px 10px;
    background-color: #a1ffaf;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor:pointer;
}
.flowDataContent{
    background-color: #fbfbfb;
    width: 80%;
    margin-left: 10%;
    height: 250px;
    border-radius: 10px;
    border: solid 1px;
    margin-top: 87px;
    box-shadow: 3px 3px 50px 0px #003580;
    position:relative;
}
.flowDataWrapper{
    background-color: #00358052;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 10px;
    color: var(--quility-dark-text);
}
.flowDataInfoTitle{
    display: inline-block;
    font-weight: 700;
    color: inherit;
    width: 150px;
}
.flowDataInfoInfo{
    display: inline-block;
}
.flowDataInfoWrapper{
    margin: 10px 0px 10px 30px;
}
.attachedFile{
    border: solid 1px #567cb1;
    width: 50%;
    text-align: left;
    text-indent: 10px;
    border-radius: 5px;
    margin: 10px auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: 2px 2px 10px -5px #567cb1;
    background-color: #567cb1;
    color: #ffffff;
    cursor: pointer;
}
.filterQueueOpen{
    height: 350px;
    overflow: auto;
    width: 90%;
    margin: 5px auto;
    border-radius: 5px;
}
.queueFilterButtons{
    width: 90%;
    margin: auto;
}
.queueItemWrapper{
    display:inline-block;
}
.textTemplateHolder{
    position: absolute;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 22px;
    font-weight: unset;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
}
.textTemplateHolder:hover{
    background-color: #3a69aa;
    color:#ffffff;
}
.textTemplateSelection:hover{
    background-color:#f1f1f1;
    cursor: pointer;
}
.textTemplateSelection{
    padding: 10px 10px;
}
.textTemplateWrapper{
    margin-top: 42px;
    border: solid 1px #f1f1f1;
    padding: 10px 0px;
    position: absolute;
    height: 300px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 3px 3px 10px -5px;
    border-radius: 5px;
}
.demo {
    width: 100px;
    min-height: 20px;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 1px solid black;
    border: solid 1px;
    width: 95%;
    border-radius: 10px;
    min-height: 40px;
    max-height: 150px;
    font-family: inherit;
    font-size: 16px;
    background-color: #ffffff;
    text-align: left;
    padding: 5px;
    margin-left: 5px;
}
.sendBar{
    width: 90%;
}
.dispositionHolder{
    margin: auto;
    display: block;
    position: absolute;
    width: 100%;
    margin-top: -25px;
    bottom: 30px;
}
.activeLeadCardButton{
    background-color: #00357f;
    color: #ffffff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.upcomingCallWrapper{
    width: 200px;
    margin: 10px auto;
    line-height: 30px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
    position: relative;
}
.upcomingOutterWrapper{
    margin: 30px;
}
.calSelectionWrapper{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #16356b52;
    width: 100%;
    height: 100%;
    z-index: 5;
}
.calSelectionContent{
    background-color: #ffffff;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    height: 200px;
    border-radius: 5px;
}
.recBlock{
    border: solid 2px var(--quility-border-color);
    width: 90%;
    margin: auto;
    height: 100px;
    margin: 20px auto;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 3px 4px 10px -5px;
}
.recName{
    font-size:20px;
    font-weight: 700;
    line-height: normal;
}
.recWrapper{
    overflow: scroll;
    height: 87%;
}
.recDescription{
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    background-color: var(--quility-block-background-color);
    position: absolute;
    border: solid 1px;
    left: 0;
    height: 350px;
    overflow: scroll;
    box-shadow: 3px 3px 20px;
    border-radius: 5px;
}
.hiddenDescription{
    display: none;
}
.viewRecDescription{
    width:fit-content;
    margin: 10px;
}
.viewRecDescription:hover > .hiddenDescription{
    display:block;
}
.recLink{
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    display:inline-block;
    margin: 0px 5px;
}
.noDeco{
    text-decoration: none;
    color: inherit;
}