:root {
    --quility-color:#2253a7;

    --quility-dark-green:   #193c79;
    --quility-extra-dark-green:#112953;
    --quility-medium-dark-green:#2253a7;

    --quility-button-color: #47c3b2;
    --quility-dark-hover-color:#2253a7;
    --quility-light-hover-color:#799cba;
    --quility-block-background-color:#f1f1f1;
    --quility-block-header-background-color:#f1f1f1;
    --quility-left-side-bar-color:#193c79;
    --quility-text-on-background:#ffffff;
    ---quility-block-header-background-color: #ffffff;
    --quility-color-button-text-color:#000f2c;
    --quility-nested-block-background-color : #ffffff;
    
    --quility-light-background: #ffffff;
    --quility-accent-background:#94bbd5;
    --quility-burst-color: #000000a8;
    --quility-veil: #11295380;
    --quility-border-color: #c2c2c2;
    
    --quility-dark-text: #2d2d2d;
    --quility-light-text: #ffffff;
    --quility-close-window-button: #000000;
    --quility-hover-grey-color:#94bbd5;

    --quility-input-background-color: #efefef;
    --quility-input-border-color:#a4a4a4bf;

    --background-color: #112953;
    --secondary-color: #c5d9f0;

    --quility-button-color-hover: #005751;
    --quility-button-color-destructive: #f95951;
    --quility-button-color-destructive-hover: #b20221;
    --quility-deactive-color:#a1c3be;
    --quility-header-color:#f1f1f1;
}