.workflowBuilderSelectionBoxHeader{
    height: 40px;
    line-height: 40px;
    background-color: #e4effc;
    text-align: center;
    font-weight: 700;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.workflowBuilderSelectionBoxWrapper{
    border: solid 1px #c5d9f0;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0px;
}

.workflowBuilderSelectionItem{
    border: dotted 1px;
    height: 35px;
    line-height: 35px;
    text-indent: 20px;
    margin: 15px 5px;
    border-radius: 5px;
}
.workflowBuilderSelectionContent{
    max-height: 300px;
    overflow: auto;
    height: 0px;
    transition:all .5s;
}
.workflowBuilderRight{
    min-width: 300px;
    flex: 2;
}
.workflowBuilderLeft{
    flex:5;
    height: 600px;
    overflow: auto;
}
.expandWorkflowBlock{
    height: 300px;
}
.draggedElement{
    line-height: 40px;
    background-color: #e4effc;
    text-align: center;
    font-weight: 700;
    width: 250px;
    margin: 10px auto;
    border: solid 1px #c5d9f0;
    border-radius: 5px;
    position: relative;
}
.draggedTitle{
    font-weight: 500;
}
.workflowStartBlock{
    width: 250px;
    height: 120px;
    border: solid 1px #d9d9d9;
    border-radius: 5px;
    margin: auto;
    background-color: #f3f3f3;
}
.workflowStartBlockTitle{
    border: solid 1px #9d9d9d;
    width: 90%;
    margin: auto;
    line-height: 40px;
    border-radius: 5px;
    text-align: center;
    background-color: #c9c9c9;
    margin-top: 30px;
}
.dottedLine{
    height: 60px;
    border-left: dotted;
    margin: auto;
    display: inline-block;
    margin-left: 50%;
}
.dropZone{
    border: 1px dotted rgb(170 170 170);
    padding: 16px;
    min-height: 150px;
    width: 350px;
    margin: auto;
    border-radius: 5px;
}
.dropZoneTitle{
    text-align: center;
    display: inline-block;
    width: 100%;
    line-height: 150px;
}
.removeFromFlow{
    position: absolute;
    top: -10px;
    right: -10px;
    border: solid 1px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    border-radius: 50%;
    font-size: 12px;
    background-color: #fbfbfbfb;
    cursor: pointer;
}
.editWorkflowButton{
    width: 100px;
    text-align: center;
    line-height: 25px;
    border-radius: 5px;
    cursor: pointer;
}
.editWorkflowBuilderLeft{
    height: calc(100% - 25px);
    margin: 5px;
    width: 60%;
    display: inline-block;
    vertical-align: top;
}
.editWorkflowBuilderRight{
    padding: 10px;
    margin: 5px;
    width: 25%;
    display: inline-block;
    height: calc(100% - 45px);
    vertical-align: top;
}
.advanceWorkflow{
    width: 150px;
    text-align: center;
    line-height: 35px;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px;
    margin-top: -20px;
}
.quilityDataLine:hover > .hoverIcon{
    display:inline-block;
}
.workflowBubble{
    background-color: var(--quility-accent-background);
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    margin: 5px 0;
    padding: 4px 15px;
    vertical-align: super;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
    margin-left: 5px;
}
.workflowInfoBox{
    position: absolute;
    border: solid 2px var(--quility-border-color);
    width: 94%;
    height: 300px;
    background-color: var(--quility-header-color);
    box-shadow: 5px 5px 60px -5px;
    z-index: 2;
    left: 3%;
    border-radius: 5px;
}
.infoWrap{
    width: 80%;
    margin: auto;
}
.flowStopped{
    background-color:var(--quility-button-color-destructive) !important;
}